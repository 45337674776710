<template>
  <div class="active-challenges">
    <div class="section-header">
      <div class="header-title">{{ $t("challenges") }}</div>
      <div class="header-btn cursor-pointer" @click="addChallenge()">
        {{ $t("see_all") }}
      </div>
    </div>
    <div class="section-content">
      <div class="default-list">
        <div
          class="challenge-item"
          v-for="(challenge, index) in activeChallenges"
          v-bind:key="index"
        >
          <progress-circle
            :icon="challenge.icon_incomplete_url"
            :stepsDone="challenge.steps_done"
            :stepsNeeded="challenge.steps_needed"
          />
          <div class="challenge-name">{{ challenge.name }}</div>
        </div>
        <div class="challenge-item" v-if="activeChallenges.length <= 3">
          <div class="add-challenge cursor-pointer" @click="addChallenge()">
            <div class="add-icon">
              <div class="line"></div>
              <div class="line line-rotate"></div>
            </div>
          </div>
          <div class="challenge-name">{{ $t("add_challenge") }}</div>
        </div>
      </div>
      <!-- mobile -->
      <div
        class="mobile-list"
        :style="{
          'justify-content': activeChallenges.length <= 1 ? 'center' : 'start',
        }"
      >
        <div
          class="challenge-item"
          v-for="(challenge, index) in activeChallenges"
          v-bind:key="index"
        >
          <progress-circle
            :icon="challenge.icon_incomplete_url"
            :stepsDone="challenge.steps_done"
            :stepsNeeded="challenge.steps_needed"
          />
          <div class="challenge-name">{{ challenge.name }}</div>
        </div>
        <div class="challenge-item">
          <div class="add-challenge cursor-pointer" @click="addChallenge()">
            <div class="add-icon">
              <div class="line"></div>
              <div class="line line-rotate"></div>
            </div>
          </div>
          <div class="challenge-name">{{ $t("add_challenge") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProgressCircle from "../../../components/ProgressCircle.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["challengesList"]),
    activeChallenges() {
      return _.filter(this.challengesList, (challenge) => {
        return (
          challenge.committed && challenge.steps_done !== challenge.steps_needed
        );
      }).slice(0, 4);
      //   return this.challengesList.slice(0, 3);
    },
  },
  components: {
    ProgressCircle,
  },
  methods: {
    ...mapActions(["getChallenges"]),
    addChallenge() {
      this.$router.push({ path: "/tropaion/challenges" });
    },
  },
  async mounted() {
    await this.getChallenges();
  },
};
</script>
<style lang="scss" scoped>
.active-challenges {
  height: 300px;
  width: 100%;
  padding-top: 46px;
  background: #121212;
  margin-bottom: 4px;

  display: flex;
  flex-direction: column;
  .section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 44px;
    .header-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;

      letter-spacing: 0.0025em;
      text-transform: capitalize;
    }
    .header-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;

      letter-spacing: 0.04em;
      text-transform: uppercase;
      padding: 0 14px;
    }
  }
  .section-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .challenge-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 110px;
      width: 100%;
    }
    .challenge-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      text-align: center;

      margin-top: 12px;
    }
    .add-challenge {
      height: 90px;
      width: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #282828;
      border-radius: 100%;
      .add-icon {
        height: 24px;
        width: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .line {
          height: 2px;
          width: 100%;
          background: #ffffff;
          border-radius: 5px;
        }
        .line-rotate {
          transform: rotate(90deg);
          position: absolute;
        }
      }
    }
    .default-list {
      display: flex;
      //   flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      //   display: grid;
      //   grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
      //   gap: 40px;
      justify-content: center;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      .challenge-item {
        margin: 0 20px;
      }
    }
    .mobile-list {
      display: none;
      //   display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }
}
@media screen and (max-width: 899px) {
  .active-challenges {
    .section-header {
      padding: 0 30px;
    }
  }
}
@media screen and (max-width: 499px) {
  .active-challenges {
    height: 236px;
    max-width: 100vw;
    padding-top: 36px;
    .section-header {
      padding: 0 16px;
      .header-title {
        font-size: 16px;
        line-height: 20px;
      }
      .header-btn {
        display: block;
      }
    }
    .section-content {
      .default-list {
        display: none;
      }
      .mobile-list {
        display: flex;
        //   flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        // padding: 0 14px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        .challenge-item {
          margin: 0 14px;
          flex: 0 0 auto;
        }
        .challenge-item:first-child {
          margin-left: 16px;
        }
        .challenge-item:last-child {
          padding-right: 16px;
        }
      }
      .mobile-list::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>