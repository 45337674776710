<template>
  <div class="grid-el">
    <svg height="90" width="90" v-if="progress !== 0">
      <circle fill="rgba(33, 31, 31, 0.9)" r="45.5" cx="45" cy="45"></circle>
      <circle
        r="43"
        cx="45"
        cy="45"
        stroke="#FFFFFF"
        stroke-width="4"
        fill="none"
        :stroke-dasharray="calculateCircleBorder"
        :stroke-dashoffset="totalProgressBorder"
      ></circle>

      <circle
        r="28"
        cx="45"
        cy="45"
        stroke="#282828"
        stroke-width="26"
        :stroke-dasharray="calculateCircumference"
        :stroke-dashoffset="totalProgress"
        fill="none"
      ></circle>
    </svg>
    <svg v-if="progress === 0" height="90" width="90">
      <circle
        stroke="rgba(255, 255, 255, 0.15)"
        stroke-width="1"
        r="44.5"
        fill="#0F0D0D"
        cx="45"
        cy="45"
      />
    </svg>
    <img :src="icon" alt="" />
  </div>
</template>
<script>
export default {
  data: () => ({}),
  props: ["icon", "stepsDone", "stepsNeeded"],
  computed: {
    calculateCircumference() {
      return 2 * Math.PI * 28;
    },
    calculateCircleBorder() {
      return 2 * Math.PI * 43;
    },
    totalProgress() {
      return (this.calculateCircumference * (100 - this.progress)) / 100;
    },
    totalProgressBorder() {
      return (this.calculateCircleBorder * (100 - this.progress)) / 100;
    },
    progress() {
      return Math.floor((100 * this.stepsDone) / this.stepsNeeded);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
// If SVG circles solution won't work well, here is another realization
// https://codepen.io/jo-asakura/pen/stFHi

svg {
  transform: rotate(-90deg);
  //   animation: offsettozero 5s linear forwards;
}

// @keyframes offsettozero {
//   to {
//     stroke-dashoffset: 0;
//   }
// }

.grid-el {
  height: 90px;
  width: 90px;
  position: relative;
  img {
    position: absolute;
    left: 15px;
    bottom: 12px;
    height: 60px;
    width: 60px;
  }
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
}
</style>